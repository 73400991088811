import Observer from './vendor/observer.min';
import { formsPackage } from './functions/form';
import masksPackage from './functions/masks';
import setConditions from './functions/conditions';
import repeaterButtons from './functions/repeaters';

export const OBSERVER = Observer.getInstance();

/*
addEventListener('DOMContentLoaded', () => {
  initFormBuilder()
})
*/

function getCookie(cname) {
  let name = cname + '=';
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  for (let i = 0; i <ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
}

export const initFormBuilder = (withRecaptcha = true, classeContainerFormBuilder = '.dynamicForm') => {
  formsPackage(classeContainerFormBuilder);
  masksPackage();
  initFileUpload(classeContainerFormBuilder);
  setConditions();

  repeaterButtons();

  if (withRecaptcha && document.querySelector('.input-recaptcha')) {
    if ((getCookie('cc_cookie_byscuit') && !getCookie('cc_cookie_byscuit').includes('functional'))) {
      // Affiche l'avertissement
      document.querySelector('#byscuit_recaptcha_and_disabled_cookies').setAttribute('style', 'display:block;');

      // Cache le form
      const forms = document.querySelectorAll('.c-form');
      forms.forEach(form => {
        form.setAttribute('style', 'display:none;');
      });

      // Supprime les boutons de submit pour pas qu'on puisse submit en réaffichant le form avec l'inspecteur
      const submitBtns = document.querySelectorAll('.form-builder-submit');
      submitBtns.forEach(btn => {
        btn.remove();
      });
    } else if (!getCookie('cc_cookie_byscuit') || getCookie('cc_cookie_byscuit').includes('functional')) {
      const script = document.createElement('script');

      script.setAttribute('type', 'text/javascript');
      script.setAttribute('src', 'https://www.google.com/recaptcha/api.js?hl=' + document.documentElement.lang + '&render=explicit');
      document.body.appendChild(script);

      setTimeout(() => {
        recaptchaFormsCallback();
      }, 1000);
    }
  }
};

window.initFormBuilder = initFormBuilder;
